import React, { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link, useLocation } from 'react-router-dom';
import "./Navbar.scss";
import Progress from "./Progress";
const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmenuToggle = (e) => {
    e.preventDefault();
    setIsSubMenuOpen(!isSubMenuOpen);
  };


  const location = useLocation();
  const isHome = location.pathname === '/home' || location.pathname === '/';

  return (
    <>
      <div 
      className={`Evbar ${isHome ? 'home' : ''}`}
      
      >
        <nav className="navbar " >
          <Link to="/home">
            <img
              data-src="https://sitoweb2024-storagec2834-staging.s3.eu-west-3.amazonaws.com/public/sitoweb/Logo+Keytech_Quadricromia.svg"
              className="navbar__logo lazyload"
              alt="Logo Keytech"
              width={200}
            />
          </Link>

          <div className="navbar__icon" onClick={handleToggle}>
            {isOpen ? <FiX /> : <FiMenu />}
          </div>
          <ul className={`navbar__links ${isOpen ? "navbar__showMenu" : ""}`}>
            <li className="navbar__item  " onClick={handleToggle}>
              <Link to="/home">Home</Link>
            </li>

            <li className="navbar__item" onClick={handleToggle}>
              <Link to="/expertise">Expertise</Link>
            </li>
            <li className="navbar__item" onClick={handleToggle}>
              <Link to="/about-us">About Us</Link>
            </li>
            <li className="navbar__item" onClick={handleToggle}>
              <Link to="/keysolutions">KeySolutions</Link>
            </li>
           
            <li className="navbar__item" onClick={handleToggle}>
              <Link to="/news">News</Link>
            </li>
            {/* <li className="navbar__item" onClick={handleToggle}>
              <Link to="/academy">Academy</Link>
            </li> */}
            <li className="navbar__item" onClick={handleToggle}>
              <Link to="/career">Career</Link>
            </li>
         
            <li className="navbar__item" onClick={handleToggle}>
              <Link to="/contact-us">Contact us</Link>
            </li>
          </ul>
        </nav>
        <Progress />
      </div>
    </>
  );
};

export default NavBar;
